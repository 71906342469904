<template>
	<div class="bg-white p-4 rounded border shadow-sm h-100">
		<b-overlay :show="loading" variant="light" spinner-type="grow">

			<section class="rounded bg-light-app px-4 pt-4 pb-2 mb-4 position-relative">
				<h1 class="display-4">
					Student Profile Report
					<small class="text-muted">Display all information held on file for a student</small>
				</h1>
			</section>

			<div class="shadow pb-4">

				<error-alert :error="error" class="m-4"/>

				<div class="text-center px-4 pt-4">
					<b-button @click="searchVisible = !searchVisible" variant="light" class="">Toggle Student Search
					</b-button>
				</div>
				<b-collapse v-model="searchVisible">
					<div class="m-4">

						<b-input-group class="">

							<template #prepend>
								<b-button :disabled="!filterText" variant="faded" size="sm"
								          class="shadow-none"
								          @click="filterText = null">
									<b-icon v-if="!filterText" icon="search"
									        aria-hidden="true"></b-icon>
									<b-icon v-if="filterText" icon="x-circle-fill"
									        aria-hidden="true"></b-icon>
								</b-button>
							</template>

							<b-form-input
									v-model="filterText"
									size="md"
									placeholder="Type to filter"
							></b-form-input>

						</b-input-group>

						<b-list-group class="mt-4 overflow-auto" style="max-height: 30vh;">
							<b-list-group-item
									button
									:active="student && student.id === s.id"
									v-for="s in filteredStudents"
									:key="s.id"
									v-html="s.displayName"
									class="text-left"
									@click="selectStudent(s)"
							></b-list-group-item>
						</b-list-group>

					</div>
				</b-collapse>

				<section v-if="student" class="m-4">

					<b-card no-body class="overflow-hidden">

						<template #header>
							<h1 class="mb-0">{{ student.fullName }}</h1>
						</template>

						<b-row no-gutters>
							<b-col md="4">
								<b-card-body>

									<div class="w-100 text-left identifier bg-primary text-white px-4 py-2 rounded">
										CEMAA{{ student.identifier }}
									</div>

									<b-list-group class="mt-2">

										<b-list-group-item>

											<div class="">
												<b-icon icon="envelope" class="mr-3"></b-icon>
												<span>{{ student.email || '-' }}</span>
											</div>

										</b-list-group-item>

										<b-list-group-item>

											<div class="">
												<b-icon icon="telephone" class="mr-3"></b-icon>
												<span>{{ student.mobile || '-' }}</span>
											</div>

										</b-list-group-item>

										<b-list-group-item>

											<div class="">
												<b-icon icon="globe" class="mr-3"></b-icon>
												<span>{{ student.addressFormatted || '-' }}</span>
											</div>

										</b-list-group-item>

									</b-list-group>

								</b-card-body>
							</b-col>
							<b-col md="8">
								<b-card-body>
									<table class="table">
										<tbody>
											<tr>
												<th style="width: 35%">Date of Birth</th>
												<td>{{ student.dateOfBirth || '-' }}</td>
											</tr>
											<tr>
												<th>Main Location</th>
												<td>{{ student.mainLocation }}</td>
											</tr>
											<tr>
												<th>Standing Order Day</th>
												<td>{{ student.standingOrderDayOfMonth }}</td>
											</tr>
											<tr>
												<td colspan="2" class="px-0">
													<table class="table">
														<thead>
															<tr class="table-dark text-dark">
																<th style="width: 35%">Class</th>
																<th>Fee</th>
															</tr>
														</thead>
														<tbody v-if="student.classes.length > 0">
															<tr v-for="item in student.classes" :key="item.classId">
																<td>{{ item.className }}</td>
																<td>£{{ item.monthlyFee.toFixed ( 2 ) }}</td>
															</tr>
														</tbody>
														<tbody v-else>
															<tr>
																<td colspan="2"><em>No classes assigned</em></td>
															</tr>
														</tbody>
													</table>
												</td>
											</tr>
										</tbody>
									</table>
								</b-card-body>
							</b-col>
						</b-row>

					</b-card>

					<b-card title="Payment History" class="mt-4">
						<table class="table">
							<thead>
								<tr>
									<th>Date</th>
									<th>Amount</th>
									<th>Type</th>
									<th>Method</th>
									<th>Notes</th>
								</tr>
							</thead>
							<tbody v-if="student.payments.length === 0">
								<tr>
									<td colspan="5"><em>No payments on file</em></td>
								</tr>
							</tbody>
							<tbody v-else>
								<tr v-for="payment in student.payments" :key="payment.id">
									<td>{{ payment.dateFormatted }}</td>
									<td>£{{ payment.amount }}</td>
									<td>{{ payment.transactionType.name }}</td>
									<td>{{ payment.paymentMethod.name }}</td>
									<td>{{ payment.notesFormatted }}</td>
								</tr>
							</tbody>
						</table>
					</b-card>

				</section>

			</div>

		</b-overlay>
	</div>
</template>

<script>

import { orderBy }  from 'lodash-es';
import { DateTime } from 'luxon';

export default {

    name      : 'ReportStudentProfile',
    mixins    : [],
    components: {},

    props: {},

    data () {
        return {

            loading: true,
            error  : false,

            searchVisible : true,
            filterText    : '',
            students      : [],
            student       : null,
            classLocations: [],
            classTypes    : []

        };
    },

    computed: {

        filteredStudents () {

            const filter = ( this.filterText || '' ).toLowerCase ();

            return orderBy (
                this.students
                    .map ( x => {

                        x.fullName = `${ x.firstName } ${ x.lastName }`.trim ();
                        x.displayName = `${ x.fullName } (CEMAA${ x.identifier })`;
                        x.addressFormatted = Object
                            .keys ( x.address )
                            .filter ( y => ( x.address[ y ] || '' ).length > 0 )
                            .map ( y => x.address[ y ] )
                            .join ( '. ' );

                        const location = this.classLocations.find ( y => y.id === x.mainClassLocationId );
                        x.mainLocation = location ? location.name : '-';

                        x.classes = x.classes.map ( y => {

                            const classType = this.classTypes.find ( z => z.id === y.classId );
                            y.className = classType ? classType.name : '-';
                            return y;

                        } );

                        x.payments = orderBy ( x.payments, [ 'paymentDate' ], [ 'desc' ] );
                        x.payments = x.payments.map ( y => {

                            y.dateFormatted = DateTime
                                .fromISO ( y.paymentDate )
                                .toLocaleString ( DateTime.DATE_MED )
                            ;

                            y.amount = parseFloat ( y.amount )
                                .toFixed ( 2 );

                            y.notesFormatted = ( y.notes || [] ).join ( '. ' );

                            return y;

                        } );

                        return x;
                    } )
                    .filter ( x => {
                        if ( filter.length === 0 ) {
                            return x;
                        } else if ( x.fullName.toLowerCase ()
                            .includes ( filter ) ) {
                            return x;
                        } else if ( x.identifier.toString ()
                            .includes ( filter.replace ( 'CEMAA', '' ) ) ) {
                            return x;
                        }
                    } ),
                [ 'fullName' ],
                [ 'asc' ]
            );
        }

    },

    methods: {

        selectStudent ( student ) {
            this.student = student;
            this.searchVisible = false;
        }

    },

    async mounted () {

        // Get students
        let response = await this.$http.get (
            `${ this.config.apiRoot }/students`,
            {
                responseType   : 'json',
                withCredentials: false // CORS dev server
            }
        );
        this.students = response.data.records;

        // Get class locations
        response = await this.$http.get (
            `${ this.config.apiRoot }/locations`,
            {
                responseType   : 'json',
                withCredentials: false // CORS dev server
            }
        );
        this.classLocations = response.data.records;

        // Get classes
        response = await this.$http.get (
            `${ this.config.apiRoot }/classes`,
            {
                responseType   : 'json',
                withCredentials: false // CORS dev server
            }
        );
        this.classTypes = response.data.records;

        this.loading = false;

    }

};

</script>

<style scoped lang="scss">

.identifier {
	font-size: 1.4rem;
	display: inline-block;
}

</style>
